<template>
	<div class="bg-mafia-dark flex-column position-relative">
		<div class="flex-1 flex-column justify-center">
			<div><img :src="require('@/assets/image/web/ico_logo_large.svg')" /></div>
			<div class="mt-20">
				<img :src="require('@/assets/image/web/ico_logo_word.svg')" />
			</div>
			<div class="mt-20">문화예술 전용 커뮤니티 <b>마피아 프로젝트</b></div>
			<div class="mt-30"><h3 class="color-white">휴대폰 본인 인증</h3></div>

			<div class="social_login pa-20">

				<div>
					<div class="justify-space-between radius-20">
						<input
							v-model="item.mphone_number"
							placeholder="휴대폰 번호"
							:rules="[$rules.numeric(item, 'mphone_number', 11)]"
							:disabled="is_number"

							class="input-box bg-white "
						>
						<button
							class="btn-inline btn-primary"
							:disabled="is_send"

							@click="postRequest"
						>요청</button>
					</div>
				</div>

				<div class="mt-20">
					<div class="justify-space-between radius-20">
						<input
							v-model="item.certfc_number"
							maxlength="6"
							placeholder="6자리 인증번호 입력"
							:rules="[$rules.numeric(item, 'certfc_number', 6)]"
							:disabled="is_code"
							class="input-box bg-white "
						>
						<button
							class="btn-inline btn-primary"
							:disabled="item.certfc_number.length != 6"

							@click="onPin"
						>확인</button>
					</div>
				</div>

			</div>

			<p
				class="copyright mt-20 color-gray"
			>Copyright © MAFI Inc. </p>
		</div>

		<PIN
			v-if="is_pin"
			:options="pin_option"
			style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 999999"

			@click="pinClick"
			@cancel="pinCancel"
			@lostPinConfirm="pinClick"
		></PIN>
	</div>
</template>

<script>

import PIN from "@/view/Auth/mafia007";

export default {
	name: 'pin_sms'
	, components: {PIN}
	, data: function(){
		return {
			program: {
				name: 'sms'
				, title: 'PIN 번호 분실 복구'
				, not_header: true
				, not_footer: true
				, type: 'auth'
			}
			, is_pin: false
			,pin_option: {
				pin_type: 'lost'
				, is_can_cancel: true
			}
			, item: {
				mphone_number: ''
				, certfc_request_key: ''
				, certfc_number: ''
			}
			, nickname: ''
			, member_number: ''
			, pinnumber: ''
			, confirmation_pinnumber: ''
		}
	}

	, computed: {
		is_send: function(){
			let t = true
			if(!this.item.certfc_request_key && this.item.mphone_number.length == 11){
				t = false
			}

			return t
		}
		, is_number: function(){
			let t = true
			if(this.item.certfc_request_key == ''){
				t = false
			}
			return t
		}
		, is_code: function(){
			let t = true
			if(this.item.certfc_request_key && this.item.mphone_number.length == 11){
				t = false
			}
			return t
		}
	}
	, methods: {
		postOtp: async function(){
			this.$log.console('postOtp')
			try{
				this.$bus.$emit('on', true)
				if(!this.certfc_number){
					throw 'OTP 번호를 입력하세요'
				}
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_google_otp
					, data: {
						certfc_number: this.certfc_number
					}
					, type: true
				})

				if(result.success){
					this.is_pin = true
				}else{
					throw result.message
				}

			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify',  { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,pinClick: async function(pin){
			this.$log.console(pin)
			this.pinnumber = pin
			this.confirmation_pinnumber = pin
			await this.postConfirm()
		}
		,pinCancel: function(){
			this.$log.console('cancel')
			this.is_pin = false
			this.pin_option.pin_type = 'lost'
		}
		, getGoogleOtpKey: async function(){
			this.$log.console('getGoogleOtpKey')
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_google_otp_key
					, data: {
					}
					, type: true
				})

				if(result.success){
					this.$log.console(result.data)
					this.item.mphone_number = result.data.mphone_number
					this.item.nickname = result.data.nickname
					this.item.member_number = result.data.member_number
				}else{
					throw result.message
				}

			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify',  { type: 'error', message: e})
				this.$bus.$emit('to', {name: 'join'})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,clipBoard: function (val){
			const t = document.createElement("textarea");
			document.body.appendChild(t);

			t.value = val;
			this.$log.console('t.value', t.value)
			t.select();
			t.setSelectionRange(0, 9999)
			document.execCommand('copy');
			document.body.removeChild(t);
		}
		, postRequest: async function  (){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_pin_lost
					, data: {
						mphone_number: this.item.mphone_number
					}
					, type: true
				})

				if(result.success){
					this.$log.console(result.data)
					this.item.certfc_request_key = result.data.certfc_request_key
				}else{
					throw result.message
				}

			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify',  { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, postConfirm: async function  (){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_pin_befor_login
					, data: {
						mphone_number: this.item.mphone_number
						, certfc_request_key: this.item.certfc_request_key
						, certfc_number: this.item.certfc_number
						, pinnumber: this.pinnumber
						, confirmation_pinnumber: this.confirmation_pinnumber
					}
					, type: true
				})

				if(result.success){

					await this.$router.push({ name: 'login'})
					/*
					if(result.data.session_token){
						await this.$encodeStorage.setSessionToken(result.data.session_token)
					}
					await this.$encodeStorage.setSessionAt(result.data)

					this.$bus.$emit('setUser', result.data)

					 */
				}else{
					throw result.message
				}

			}catch (e) {
				this.$log.console(e)
				this.onPin()
				this.$bus.$emit('notify',  { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onPin: function(){
			if(this.is_pin){
				this.is_pin = false
				setTimeout( () => {
					this.is_pin = true
				}, 5)
			}else{
				this.is_pin = true
			}
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
	}
}
</script>